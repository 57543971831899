import React, { useState, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import BackgroundCharts from './BackgroundCharts'
import '../styles/ChatBot.scss';
import sendRequest from '../helpers/send-request'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Switch} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from '@mui/icons-material/Close';
import Dragable from './Dragable';

const ChatBot = () => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [showSQL, setShowSQL] = useState(false);

  const handleShowSQL = (event) => {
    setShowSQL(event.target.checked);
  };

  const [open, setOpen] = useState(true);
  const [maximized, setMaximized] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setMaximized(false);
  };

  const handleMinimize = () => {
    setMaximized(false);
  };

  const handleMaximize = () => {
    setMaximized(true);
  };


  const [messages, setMessages] = useState(() => {
    const storedMessages = localStorage.getItem('chatMessages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  const handleSendMessage = async (message) => {
    const userMessage = {role: 'user', content: message};
    setIsLoading(true);
    const messageHistory = [...messages, userMessage]
    setMessages(messageHistory);

    const writeResponse = (llmResponse) => {
      
      setMessages(() => [...messageHistory, { 
        role: 'assistant',
        ...llmResponse
      }])
    }
    const stopLoading = () => {
      setIsLoading(false);
    }

    sendRequest(message, writeResponse, stopLoading);
  };

  const handleClearChatHistory = () => {
    localStorage.clear();
    setMessages([])
  }
  return (
    <main className="main-container">
      <BackgroundCharts/>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        className='modal-button'
      >
        <ChatIcon/>
      </Button>

      <Dialog
        open={open} 
        onClose={handleClose} 
        PaperComponent={Dragable} 
        className='modal-container'       
        fullScreen={maximized}
      >
        <DialogTitle id="draggable-dialog-title" className='modal-title'>
          <span>
            Production Assistant
          </span>
          <div>
            {maximized ? <IconButton onClick={handleMinimize} size="small" className='header-button'>
              <CloseFullscreenIcon />
            </IconButton> :
            <IconButton onClick={handleMaximize} size="small" className='header-button'>
              <OpenInFullIcon />
            </IconButton>}
            <IconButton onClick={handleClose} size="small" className='header-button'>
              <CloseIcon/>
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className="chat-messages">
          <div>
            <Switch
              checked={showSQL}
              onChange={handleShowSQL}
              color="primary"
              inputProps={{ 'aria-label': 'toggle button' }}
            />
          Show Sql
          </div>
            {messages.map((msg, index) => (
              <ChatMessage key={index} message={msg} showSQL={showSQL}/>
            ))}
        </DialogContent>
        <DialogActions className="chat-actions">
          <ChatInput onSendMessage={handleSendMessage} onClearChatHistory={handleClearChatHistory} isLoading={isLoading}/>
        </DialogActions>
      </Dialog>
    </main>
  );
};

export default ChatBot;