import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport  } from '@mui/x-data-grid';
import '../styles/TableGraph.scss';


const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const TableGraph = ({ data, className }) => {
  const rows = data.slice(1).map((row, index) => ({
    id: index,
    col1: row[0],
    col2: row[1],
  }));

  const columns = [
    { field: 'col1', headerName: data[0][0], width: 200, renderCell: (params) => (
      <div dangerouslySetInnerHTML={{ __html: params.value }} />
    ) },
    { field: 'col2', headerName: data[0][1], width: 150, renderCell: (params) => (
      <div dangerouslySetInnerHTML={{ __html: params.value }} />
    ) },
  ];

  return (
    <div className={`table-container ${className}`}>
      <DataGrid 
        rows={rows}
        columns={columns} 
        slots={{
          toolbar: CustomToolbar,
        }}
       />
    </div>
  );
}

export default TableGraph;