import React, { useEffect, useState } from 'react';
import LineChartGraph from './LineChartGraph';
import BarChartGraph from './BarChartGraph';
import TableGraph from './TableGraph';
import CardItem from './CardItem';
import '../styles/BackgroundCharts.scss';


const ChatMessage = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('https://api.example.com/data');
        // if (!response.ok) {
        //   throw new Error('Failed to fetch data');
        // }
        // const jsonData = await response.json();
        const jsonData = {
          linechartData: {"title":"Production Trend for <a href='well/permian_east_10'>Permian East 10</a> and <a href='well/permian_east_5'>Permian East 5</a>","yAxisTitle":"Production (Mscf/d)","xAxisTitle":"Days","pointStart":700,"series":[{"name":"<a href='well/permian_east_10'>Permian East 10</a>","data":[351,345,337,337,343,334,340,345,332,343,351,331,341,331,345,348,343,344,349,332,328,336,347,337,330,344,328,335,345,344,336]},{"name":"<a href='well/permian_east_5'>Permian East 5</a>","data":[292,299,298,282,297,297,285,283,292,294,288,296,282,294,292,285,283,289,286,283,286,283,278,285,286,288,276,278,275,290,290]}]},
          tableData: [["Well","Underperformance (Mscf)"],["<a href='well/permian_east_13'>Permian East 13</a>",132],["<a href='well/permian_east_12'>Permian East 12</a>",60],["<a href='well/permian_east_14'>Permian East 14</a>",57],["<a href='well/permian_east_11'>Permian East 11</a>",29],["<a href='well/permian_east_7'>Permian East 7</a>",25],["<a href='well/permian_east_4'>Permian East 4</a>",15],["<a href='well/permian_east_3'>Permian East 3</a>",11],["<a href='well/permian_east_9'>Permian East 9</a>",5]],
          barchartData: {"title":"Production Comparison: <a href='well/permian_east_5'>Permian East 5</a> vs <a href='well/permian_east_10'>Permian East 10</a>","yAxisTitle":"Production (Mscf/d)","xAxisTitle":"Days","series":[{"name":"<a href='well/permian_east_5'>Permian East 5</a>","data":[292,299,298,282,297,297,285,283,292,294,288,296,282,294,292,285,283,289,286,283,286,283,278,285,286,288,276,278,275,290,290]},{"name":"<a href='well/permian_east_10'>Permian East 10</a>","data":[351,345,337,337,343,334,340,345,332,343,351,331,341,331,345,348,343,344,349,332,328,336,347,337,330,344,328,335,345,344,336]}],"categories":["Day 1","Day 2","Day 3","Day 4","Day 5","Day 6","Day 7","Day 8","Day 9","Day 10","Day 11","Day 12","Day 13","Day 14","Day 15","Day 16","Day 17","Day 18","Day 19","Day 20","Day 21","Day 22","Day 23","Day 24","Day 25","Day 26","Day 27","Day 28","Day 29","Day 30","Day 31"]},
        }
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const {linechartData, tableData, barchartData} = data
  
  return (
    <div className='bachground-charts-container'>
      <div className='bachground-charts-column'>
        <LineChartGraph data={linechartData} className='bachground-chart-items'/>
        <BarChartGraph data={barchartData} className='bachground-chart-items'/>
      </div>
      <div className='bachground-charts-column'>
        <div className='bachground-charts-row'>
          <CardItem title='# Wells' textValue='193' value='14' color='#02B2AF'/>
          <CardItem title='Oil Production/Month' textValue='55' value='45' color='#2E96FF'/>
          {/* <CardItem title='Projected Production' textValue='110 B' value='85' color='rgb(252, 121, 98)'/> */}
        </div>
       <TableGraph data={tableData} className='bachground-chart-items'/>
      </div>
      <div className='bachground-charts-column'>
        <LineChartGraph data={linechartData} className='bachground-chart-items'/>
        <BarChartGraph data={barchartData} className='bachground-chart-items'/>
      </div>
    </div>
  );
};

export default ChatMessage;